import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { Rating } from "@material-ui/lab";
import { msg } from '../messages'
import { titleToLink, truncateText, get_user_review_msg, get_price_with_currency } from '../util'
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: 150,
        maxHeight: 150
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 150,
        width: "100%",
        position: "relative"
    },
    content: {
        flex: '1 0 auto',
        padding: 8,
        paddingLeft: 16,
        textAlign: "left"
    },
    cover: {
        minWidth: 90,
        height: 100,
        marginTop: "auto",
        marginBottom: "auto",
        paddingLeft: "2%",
        display: "flex"
    }
}));

export default function HomePageCard(props) {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={6} lg={4}>
            <ButtonBase
                style={{ display: "block", width: "100%" }}
                onClick={event => { document.location.href = `/${titleToLink(props.title, props.r_id)}` }}>

                <Card className={classes.root}>
                    <CardMedia
                        className={classes.cover}
                        title={props.title}
                    >
                        <img
                            src={props.img}
                            style={{
                                maxHeight: "100%", maxWidth: 80, margin: "auto",
                            }}
                            alt={props.title}
                            loading="lazy"
                            decoding="async">
                        </img>
                    </CardMedia>

                    <div className={classes.details}>

                        <CardContent className={classes.content}>
                            <Typography style={{ fontSize: 13 }}>
                                {truncateText(props.title, 40)}
                            </Typography>
                            <Rating value={props.avg_star} precision={0.1} max={5} readOnly size="small" />
                            <Typography style={{ fontSize: 10, color: "gray" }}>
                                <b>{props.count}</b> {get_user_review_msg(props.count)}
                            </Typography>
                            <Typography style={{ fontSize: 10, color: "gray" }}>
                                <b>{get_price_with_currency(props.price)}</b>
                            </Typography>
                        </CardContent>

                        <div style={{
                            textAlign: "right",
                            margin: 10,
                            position: "absolute",
                            right: 0,
                            bottom: 0
                        }}>
                            <a href={`/${titleToLink(props.title, props.r_id)}`}>{msg.read_reviews_button_text}</a>
                        </div>
                    </div>
                </Card>

            </ButtonBase>
        </Grid >

    );
}
